import React from "react"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { WithChildren } from "../../types/reactTypes"

interface AppWithToastProps extends WithChildren {}

toast.configure()

function AppWithToast(props: AppWithToastProps) {
  return props.children
}

export default AppWithToast
