import {
  LoginRequestBody,
  LoginResponseBody,
} from "@core/types/api/loginRouteTypes"
import { ApiErrorResponse, fetchApi, toJson } from "./fetchApi"
import { ApiFetchError } from "./utils/ApiFetchError"
import { DEV } from "~/config"

function handleError(response: Response) {
  return response.json().then((body) => {
    const error = body as ApiErrorResponse
    if (DEV) console.debug(error)
    const message = error.description || error.message
    if (message) {
      throw new ApiFetchError(message)
    } else throw new ApiFetchError("unknown error")
    // noinspection UnreachableCodeJS
    return null
  })
}

export function fetchLogin(args: LoginRequestBody): Promise<LoginResponseBody> {
  return fetchApi("/login", {
    method: "POST",
    body: JSON.stringify(args),
  })
    .then((response) => {
      if (!response.ok) {
        return handleError(response)
      }
      return response
    })
    .then(toJson)
}
