import React from "react"
import { toast } from "react-toastify"
import { ToastOptions, TypeOptions } from "react-toastify/dist/types"
import { ApiFetchError } from "~/services/utils/ApiFetchError"

export function alertError(anyMsg: MsgType, options?: ToastOptions) {
  return _alert(anyMsg, "error", options)
}

export function alertInfo(anyMsg: MsgType, options?: ToastOptions) {
  return _alert(anyMsg, "info", options)
}

export function _alert(
  anyMsg: MsgType,
  type: TypeOptions,
  options?: ToastOptions
) {
  const msg = getMessage(anyMsg)
  toast(msg, {
    autoClose: 5000,
    ...options,
    type,
  })
}

export function getMessage(anyMsg: MsgType) {
  if (typeof anyMsg === "string") return anyMsg
  return (
    (anyMsg as ApiFetchError).publicMessage ||
    (anyMsg as Error).message ||
    anyMsg
  )
}

type MsgType = string | Error | ApiFetchError | React.ReactNode
