import { combineReducers } from "redux"
import { createSelector } from "reselect"
import keyBy from "lodash/keyBy"
import {
  SET_PROJECT_STATS,
  SET_PROJECTS,
  SET_SIMPLE_PROJECT_STATS,
} from "./projectActions"
import { SET_LOGGED_OUT } from "../login/loginActions"
import {
  DetailedProjectStats,
  ProjectType,
  SimpleProjectStats,
} from "../../@core/types/domain/ProjectType"

interface State {
  allIds: number[]
  byId: { [key: string]: ProjectType }
  statsById: { [key: string]: DetailedProjectStats | undefined }
  simpleStatsById: { [key: string]: SimpleProjectStats | undefined }
}

function allIds(state = null, { type, payload }) {
  switch (type) {
    case SET_PROJECTS:
      return payload && payload.map((project) => project.id)
    case SET_LOGGED_OUT:
      return null
    default:
      return state
  }
}

const byIdDefault = {}
function byId(state = byIdDefault, { type, payload }) {
  switch (type) {
    case SET_PROJECTS:
      return keyBy(payload, "id")
    case SET_LOGGED_OUT:
      return byIdDefault
    default:
      return state
  }
}

const statsByIdDefault = {}
function statsById(state = statsByIdDefault, { type, payload, meta }) {
  switch (type) {
    case SET_PROJECT_STATS:
      return {
        ...state,
        [meta]: payload,
      }
    case SET_LOGGED_OUT:
      return statsByIdDefault
    default:
      return state
  }
}

function simpleStatsById(state = statsByIdDefault, { type, payload }) {
  switch (type) {
    case SET_SIMPLE_PROJECT_STATS:
      return payload
    case SET_LOGGED_OUT:
      return statsByIdDefault
    default:
      return state
  }
}

const projectReducer = combineReducers({
  byId,
  allIds,
  statsById,
  simpleStatsById,
})

const stateSelector = (state) => state["project"] as State

export const projectsById = (state) => stateSelector(state).byId
const projectsAllIds = (state) => stateSelector(state).allIds
export const projectStats = (state) => stateSelector(state).statsById
export const simpleProjectStats = (state) =>
  stateSelector(state).simpleStatsById

export const allProjects = createSelector(
  projectsById,
  projectsAllIds,
  (byId, allIds) => allIds && allIds.map((id) => byId[id])
)

export default projectReducer
