import React from "react"
import AppWithStore from "./store/AppWithStore"
import { WithChildren } from "./types/reactTypes"
import AppWithToast from "./utils/alert/AppWithToast"
import { RecoilRoot } from "recoil"

interface AppProps extends WithChildren {}

function AppWrapper(props: AppProps) {
  return (
    <AppWithToast>
      <RecoilRoot>
        <AppWithStore>{props.children}</AppWithStore>
      </RecoilRoot>
    </AppWithToast>
  )
}

export function wrapGlobalApp({ element }) {
  return <AppWrapper>{element}</AppWrapper>
}

export default AppWrapper
