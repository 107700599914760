import { ExtendableError } from "~/utils/js/ExtendableError"
import { GlobalErrorType } from "./GlobalErrorType"

export type FetchError = ApiFetchError

export class ApiFetchError extends ExtendableError {
  public publicMessage?: string
  public type?: GlobalErrorType
  public status?: number

  constructor(
    publicMessage: string = "unknown error",
    message: string = publicMessage,
    type: GlobalErrorType = null,
    info?: ResponseInfo
  ) {
    super(message)
    this.publicMessage = publicMessage
    this.type = type
    this.status = info?.status
  }
}

export interface ResponseInfo {
  status?: number
}
