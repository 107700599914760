import { fetchApi, requireOk, toJson } from "./fetchApi"
import {
  ProjectSocialStatsResponse,
  ProjectStatsResult,
  ProjectValuesResponse,
  SimpleProjectStatsResult,
} from "@core/types/api/projectRouteTypes"
import { ProjectId, ProjectType } from "@core/types/domain/ProjectType"
import { ProjectFilterType } from "@core/types/api/ProjectFilterType"
import { GetTopPostsResult } from "@core/types/api/stats/getTopPosts"
import { GetPostHistoryResult } from "@core/types/api/stats/getPostHistory"
import { PostFilter } from "@core/types/api/stats/PostFilter"
import { GetTopChannelResponse } from "@core/types/api/stats/getTopChannels"
import { queryParams } from "../utils/jsUtils"
import { ProjectStatsFilter } from "../screens/app/dashboard/sections/filter/ProjectFilter"

export function fetchProjects(): Promise<ProjectType[]> {
  return fetchApi("/project").then(requireOk).then(toJson)
}

export function fetchProjectStats(
  filter: ProjectFilterType
): Promise<ProjectStatsResult> {
  const params = createVideoFilterQuery(filter)
  return fetchApi(`/stats/project/?${params}`).then(requireOk).then(toJson)
}

export function fetchSingleProjectStats(
  projectId: ProjectId,
  filter?: ProjectStatsFilter
): Promise<ProjectStatsResult> {
  const query = filter
    ? "?" +
      queryParams({
        statusIn: filter.statusIn?.join(",") || undefined,
      })
    : ""

  return fetchApi(`/project/${projectId}/stats${query}`)
    .then(requireOk)
    .then(toJson)
}

export function fetchProjectValues(
  filter: ProjectStatsFilter
): Promise<ProjectValuesResponse> {
  const query = createVideoFilterQuery(filter)
  return fetchApi(`/stats/project/values?${query}`).then(requireOk).then(toJson)
}

export function fetchProjectSocialStats(
  filter: PostFilter
): Promise<ProjectSocialStatsResponse> {
  const query = createPostFilterQuery(filter)
  return fetchApi(`/stats/project/social?${query}`).then(requireOk).then(toJson)
}

export function fetchProjectTopPosts(
  filter: PostFilter
): Promise<GetTopPostsResult> {
  const query = createPostFilterQuery(filter)
  return fetchApi(`/stats/project/top-posts?${query}`)
    .then(requireOk)
    .then(toJson)
}

export function fetchProjectTopChannels(
  filter: PostFilter
): Promise<GetTopChannelResponse> {
  const query = createPostFilterQuery(filter)
  return fetchApi(`/stats/project/top-channels?${query}`)
    .then(requireOk)
    .then(toJson)
}

export function fetchProjectPostHistory(
  filter: PostFilter
): Promise<GetPostHistoryResult> {
  const query = createPostFilterQuery(filter)
  return fetchApi(`/stats/project/posts-history?${query}`)
    .then(requireOk)
    .then(toJson)
}

function createVideoFilterQuery(filter: ProjectStatsFilter): string {
  return queryParams({
    projectIds: filter.projectIds.join(","),
    statusIn: filter.statusIn?.join(",") || undefined,
    ebuMember: filter.ebuMember || undefined,
  })
}

function createPostFilterQuery(filter: PostFilter): string {
  return queryParams(filter)
}

export function fetchSimpleProjectStats(): Promise<SimpleProjectStatsResult> {
  return fetchApi(`/project/videoCounts`).then(requireOk).then(toJson)
}
