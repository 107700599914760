// @flow
import { createStore, applyMiddleware } from "redux"
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction"
import thunkMiddleware from "redux-thunk"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import reduxPromise from "redux-promise"
import rootReducer from "./rootReducer"

const exampleInitialState = {}

const persistedReducer = persistReducer(
  {
    key: "root",
    whitelist: ["login"],
    storage,
  },
  rootReducer
)

const initStore = (initialState = exampleInitialState) => {
  return createStore(
    persistedReducer,
    initialState,
    composeWithDevTools(
      applyMiddleware(thunkMiddleware, reduxPromise)
      // thunkMiddleware,
      // promiseMiddleware()
    )
  )
}

export const globalStore = initStore()

export default () => {
  let store = globalStore
  let persistor = persistStore(store)
  return { store, persistor }
}
